<template>
  <div id="wrapper" class="d-flex">
    <div class="sidebar" v-if="$store.state.auth.user">
      <sidebar />
    </div>
    <div class="router-view flex-grow-1">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import sidebar from "./components/sidebar";

export default {
  name: "App",
  components: {
    sidebar,
  },
  data() {
    return {
      isProfileMenuOpen: false,
    };
  },

  methods: {
    toggleProfileMenu() {
      this.isProfileMenuOpen = !this.isProfileMenuOpen;
    },
    startsWith(needle, haystack) {
      return haystack.startsWith(needle);
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          if (this.$store.state.auth.user == null) {
            console.log(
              "we don't have a user, let's redirect to the login page"
            );
            this.$router.push("/login");
          }
        });
      },
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap";

#wrapper {
  height: 100vh;
  width: 100%;
  .sidebar {
    width: 250px;
    height: 100vh; /* Set sidebar height to fill the viewport */
    // position: fixed; /* Make the sidebar fixed */
    top: 0;
    left: 0;
    overflow-y: auto;
  }
  /* Set the margin of the main content to give space for the sidebar */
  .router-view {
    background: #f1f5f9;
    overflow: scroll;
    height: 100vh;
    max-width: calc(100vw - 250px);
  }
}
</style>
