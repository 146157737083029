<template>
  <div class="form-group mt-2">
    <label :for="id" class="d-flex align-items-center">
      <span>{{label}}</span>
      <a class="help" v-if="help" :href="help" target="_blank">
        <svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24h80c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8V248c0-13.3-10.7-24-24-24H216c-13.3 0-24 10.7-24 24s10.7 24 24 24h24v64H216zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
          />
        </svg>
      </a>
    </label>
    <input type="text" class="form-control" :id="id" :placeholder="placeholder" v-model="input" :disabled="disabled" />
  </div>
</template>

<script>
export default {
  props: ["value", "label", "placeholder", "help", "disabled"],
  data() {
    return {
      input: this.value,
    };
  },
  computed: {
    id() {
      return "text_input_" + this._uid;
    },
  },
  watch: {
    input(input) {
      this.$emit("input", input);
    },
    value(value) {
      this.input = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.help {
  width: 16px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  .icon {
    width: 100%;
    height: 100%;
    fill: #adb1b4;
  }
}
</style>