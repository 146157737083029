<template>
  <div class="group" :class="{nopadding:nopadding}">
    <h5>{{label}}</h5>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["label", "nopadding"],
};
</script>

<style lang="scss" scoped>
.group {
  padding: 20px;

  h5 {
    background: #2e2e30;
    text-align: center;
    margin-top: -20px;
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 4px 4px 0 0;
    padding: 10px;
    color: white;
    border-top: 1px solid white;
  }

  &.nopadding {
    padding: 0px;

    h5 {
      background: #2e2e30;
      text-align: center;
      margin-top: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      border-radius: 4px 4px 0 0;
      padding: 10px;
      color: white;
      border-top: 1px solid white;
    }
  }
  background: rgb(236, 236, 236);
  border-radius: 5px;
  margin-bottom: 20px;
  filter: drop-shadow(0px 0px 5px rgba(#818181, 0.5));


  a {
    color: black;
  }

  .text-danger {
    a {color: #dc3644;}
  }
}
</style>