<template>
  <div class="user-menu-wrap">
    <a @click="toggleMenu()" ref="mini-photo-wrapper" class="mini-photo-wrapper" href="#">
      <img class="mini-photo" width="36" height="36" :src="avatarSvg" />
    </a>
    <div ref="menucontainer" class="menu-container" :class="{ active : showMenu  }">
      <div class="d-flex flex-column user-menu">
        <!-- <div class="user-menu-item">
          <a class="user-menu-link" href="#">Profile</a>
        </div>
        <div class="user-menu-item">
          <a class="user-menu-link" href="#">Settings</a>
        </div>-->
        <div class="user-menu-item">
          <a class="user-menu-link" href="#" @click.prevent="logout()">Logout</a>
        </div>
      </div>
    </div>
    <div class="menu-overlay" :class="{ show: showMenu }" @click="toggleMenu()"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userHash: "1",
      showMenu: false,
      avatarSvg: require("./../assets/avatars/" +
        this.computeUserHash() +
        ".svg"),
    };
  },
  mounted() {
    this.$store.state.auth.user;
  },
  methods: {
    computeUserHash() {
      let hash = this.generateHash(this.$store.state.auth.user.email);
      if (hash <= 0) {
        hash = hash * -1 + 1;
      }
      if (hash > 18) {
        hash = hash % 18;
      }
      if (hash <= 0 || hash > 18) {
        return "1";
      }
      return hash;
    },
    generateHash(input) {
      var hash = 0;
      for (var i = 0; i < input.length; i++) {
        var character = input.charCodeAt(i);
        hash = (hash << 5) - hash + character;
        hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      // We need to send a request to the server to delete the cookie from there
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style lang="scss">
.user-menu-wrap {
  .user-menu-wrap {
    position: relative;
    width: 36px;
    margin: 50px auto;
  }

  .mini-photo-wrapper {
    display: block;
  }

  .mini-photo {
    border-radius: 50%;
    position: relative;
    z-index: 3;
    box-shadow: 0px 0px 0px 2px white;
  }

  .menu-container {
    visibility: hidden;
    opacity: 0;
    position: relative;
    z-index: 3;
  }

  .menu-container.active {
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in-out;
  }

  .user-menu {
    color: black;
    position: absolute;
    top:-120px;
    right: -13px;
    background-color: #fff;
    width: 140px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 10px;
  }

  .user-menu .user-menu-link {
    display: block;
    text-decoration: none;
    color: #444 !important;
    font-size: 14px;
    padding: 5px 10px;
    color: black;
  }

  .user-menu-item:hover {
    background-color: #c2d8ee;
    color: black;
  }

  .user-menu:before {
    position: absolute;
    top: -16px;
    left: 72px;
    display: inline-block;
    content: "";
    border: 8px solid transparent;
    border-bottom-color: #ccc;
    border-bottom-color: rgba(0, 0, 0, 0.15);
  }

  .user-menu:after {
    position: absolute;
    top: 100%;
    left: 103px;
    display: inline-block;
    transform: rotate(180deg);
    content: "";
    border: 7px solid transparent;
    border-bottom-color: #fff;
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transform-origin: top right;
  transition: all 300ms ease-in-out;
  &.show {
    opacity: 1;
    pointer-events: unset;
  }
}
</style>