import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from './http.js'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        auth: {
            user: null,
            notifySessionExpired: false,
        },
        categories: {},
        categoriesReady: false,
        productsLoaded: false,
        products: [],
        theme: 'dark',
        chartType: 'trend',
        livePreviewSessionId: null,
    },
    mutations: {
        toggleTheme(state) {
            state.theme = (state.theme == 'dark') ? 'light' : 'dark'
        },
        toggleBarChart(state) {
            state.chartType = (state.chartType == 'trend') ? 'bar' : 'trend'
        },
        productsLoaded(state, value) {
            state.productsLoaded = value
        },
        setProducts(state, products) {
            state.products = products
        },
        setUser(state, user) {
            state.auth.user = user;
        },
        setLivePreviewSession(state, sessionID) {
            state.livePreviewSessionId = sessionID;
        },
        notifySessionExpired(state, expired) {
            state.auth.notifySessionExpired = expired;
        },
        setCategories(state, categories) {
            state.categories = categories
            state.categoriesReady = true
        },
    },
    actions: {
        login(context, credentials) {
            return new Promise((resolve, reject) => {
                axios
                    .post(
                        "/login",
                        {
                            email: credentials.email,
                            password: credentials.password,
                        },
                        {
                            headers: { "Content-Type": "application/json" },
                        }
                    )
                    .then(
                        (r) => {
                            console.log('response succededey')
                            if (r.status !== 200) {
                                reject({ response: r });
                                return;
                            }
                            axios.get("/api/users/me").then(
                                (r) => {
                                    this.commit("setUser", r.data.user);
                                    resolve(r)
                                },
                                () => {
                                    // If the user is logged, but it's not an admin, let's log them out and redirect back
                                    reject({ response: r });
                                }
                            )
                        },
                        (e) => {
                            reject(e)
                        }
                    );
            });
        },
        logout() {
            this.commit("setUser", null);
            return new Promise((resolve) => {
                axios
                    .post("/logout", {}, { withCredentials: true }).then(
                        (r) => {
                            resolve(r)
                        },
                        (e) => {
                            // User is already logged out, let's resolve the promise
                            resolve(e.response)
                        },
                    ).finally(() => {
                        this.commit("notifySessionExpired", false);
                    })
            })
        },
        checkLoggedUserIsAdmin() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/admin/me", {
                        withCredentials: true,
                        headers: { "Content-Type": "application/json" },
                    }).then(
                        (r) => {
                            if (r.status == 200) {
                                resolve(r.data.user);
                            }
                            reject({ response: r })
                        },
                        (e) => {
                            if (e.response.status == 401) {
                                this.commit("setUser", null);
                                // this.dispatch("logout");
                                reject(e);
                            }
                        }
                    )
            })
        },
        checkUserIsLoggedIn() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/users/me", {
                        withCredentials: true,
                        headers: { "Content-Type": "application/json" },
                    }).then(
                        (r) => {
                            if (r.status == 200) {
                                // User is actually logged in, let's override the user data in the store
                                this.commit("setUser", r.data.user);
                                resolve(r.data.user);
                            }
                        },
                        (e) => {
                            if (e.response.status == 401) {
                                // There is a problem with user cookies
                                reject(e);
                            }
                        }
                    )
            })
        },
        async syncAspects() {
            const result = axios.get('/api/search/config/aspects');
            store.commit('setCategories', (await result).data.categories);
        },
        async syncProducts() {
            const result = axios.get('/api/products?per_page=2000')
            result.then(response => {
                store.commit('setProducts', response.data.data)
                store.commit('productsLoaded', true)
            })
        },
    },
    plugins: [vuexLocal.plugin]
})


export default store