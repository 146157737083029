import axios from 'axios'
import store from './store.js'

axios.defaults.withCredentials = true

const instance = axios.create({
    baseURL: process.env.VUE_APP_VAVEURL,
    timeout: 100000,
});

instance.interceptors.response.use((response) => {
    return response
}, (error) => {
    // Check if the user is unauthenticated, then log them out
    if (error.response && error.response.status == 401) {
        console.log("Axios Interceptor: got status 401, unauthenticated");
        store.commit("setUser", null);
        store.commit("notifySessionExpired", true);
    }
    return Promise.reject(error);
})

export default instance