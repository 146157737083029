<template>
  <select-input :label="label" v-model="input" :options="selectOptions"/>
</template>

<script>
import selectInput from "../inputs/select-input.vue";

export default {
  components: {
    selectInput
  },
  props: [
    'value',
    'label',
    'options'
  ],
  data() {
    return {
      selectOptions: this.options ? this.options : [{value: 'left', label: 'Left'},{value: 'center', label: 'Center'},{value: 'right', label: 'Right'}],
      input: this.value
    }
  },
  computed: {
    id() {
      return 'text_input_' + this._uid;
    }
  },
  watch: {
    input(input) {
      this.$emit('input',input)
    },
    value(value) {
      this.input = value;
    }
  }
}
</script>