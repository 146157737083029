<template>
  <aside class="sidebar" v-if="ready">
    <header>
      <div class="row align-items-center">
        <div class="col">
          <router-link class="title me-2" to="/">
            <img class="mstore-logo" src="/mstore.svg" alt="Vave Maestro" />
            <span>VAVE MAESTRO</span>
          </router-link>
        </div>
      </div>
    </header>

    <div class="sidebar-content">
      <div class="group" v-for="group, groupkey in routes" :key="groupkey">
        <div class="group-name pe-2">{{group.name}}</div>
        <div class="routes">
          <ul :key="groupkey">
            <li v-for="route in group.routes" :key="route.name" @click="dismissModal" :class="{
                  current: route.name.startsWith($route.name) || ($route.name && $route.name.startsWith(route.name)),
                }">
              <component v-bind:is="route.meta.component" v-if="route.meta.component"></component>

              <router-link v-else :to="route.path">
                <div class="icon">
                  <i :class="route.meta.icon"></i>
                </div>
                {{route.meta.label}}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="user-bar">
      <div class="row align-items-center">
        <div class="col">
          <div v-if="user">{{user.name}}</div>
        </div>
        <div class="col-auto">
          <avatar />
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import avatar from "./Avatar";
import EventBus from "../bus";

export default {
  components: {
    avatar,
  },
  data() {
    return {
      ready: false,
      routes: {},
    };
  },
  beforeCreate() {
    this.$store.dispatch("checkLoggedUserIsAdmin").then(
      () => {
        this.ready = true;
      },
      () => {
        this.$router.push("/login");
      }
    );
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    dismissModal() {
      EventBus.$emit("dismiss-modal");
    },
  },
  watch: {},
  mounted() {
    let routes = [
      {
        name: "sales",
        routes: [],
      },
      {
        name: "content",
        routes: [],
      },
      {
        name: "dev",
        routes: [],
      },
    ];
    this.$router.options.routes
      .filter((route) => {
        return !route.meta.hidden ?? true;
      })
      .forEach((route) => {
        let group = route.meta.group;
        if (group) {
          // find the group with that name
          let groupRoute = routes.find((route) => route.name === group);
          if (groupRoute) {
            groupRoute.routes.push(route);
          } else {
            routes.push({
              name: group,
              routes: [route],
            });
          }
        }
      });
    this.routes = routes;
  },
};
</script>

<style lang="scss">
.sidebar {
  header {
    background: #2980b9;
    text-align: center;

    a {
      color: white;
      text-decoration: none;
      font-weight: bold;
      &:hover {
        color: white;
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: center;

      .mstore-logo {
        width: 40px;
        height: 40px;

        filter: invert(1);
      }
    }
  }
  background: #2c3e50;
  height: 100%;
  overflow: auto;
  width: 200px;
  display: flex;
  flex-direction: column;
  .sidebar-content {
    flex-grow: 1;
    .group {
      margin-top: 10px;
      &:not(:first-child) {
        margin-top: 20px;
      }
      .group-name {
        text-align: right;
        color: #98c9e95d;
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: bold;
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
          padding: 5px 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          text-decoration: none;
          width: 100%;
          color: rgba(255, 255, 255, 0.8);

          cursor: pointer;
        }

        .icon {
          margin: 0 10px;
          color: rgba(255, 255, 255, 0.8);
        }

        &.current {
          background-color: #55a5d9;
          color: #fff;
        }

        &:hover:not(.current) {
          background-color: #f1f5f9;
          a {
            color: #2980b9;
          }
          .icon {
            color: #2980b9;
          }
        }
      }

      // overflow: hidden;
      // max-height: 0;
      &.expanded {
        background: green;
        color: white;

        //   overflow: scroll;
        //   max-height: 1000px;
      }
    }
  }

  .user-bar {
    background: darken(#2980b9, 5%);
    color: white;
    font-weight: 300;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    .row {
      margin: 0px;
    }

    .name {
      font-weight: bold;
    }
    .domain {
      opacity: 0.5;
    }
  }
}
</style>
