<template>
  <select-input :label="label" v-model="input" :options="options ? options : defaultOptions" />
</template>

<script>
import selectInput from "../inputs/select-input.vue";

export default {
  components: {
    selectInput,
  },
  props: ["value", "label", "options"],
  data() {
    return {
      input: this.value,
      defaultOptions: [
        { value: "primary", label: "Button Primary" },
        { value: "secondary", label: "Button Secondary" },
        { value: "tertiary", label: "Button Tertiary" },
        { value: "blue", label: "Button Blue" },
        { value: "light-blue", label: "Button Light Blue" },
        { value: "yellow", label: "Button Yellow" },
        { value: "link", label: "Plain Link" },
        { value: "custom", label: "Custom" },
      ],
    };
  },
  computed: {
    id() {
      return "text_input_" + this._uid;
    },
  },
  watch: {
    input(input) {
      this.$emit("input", input);
    },
    value(value) {
      this.input = value;
    },
  },
};
</script>