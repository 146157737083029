<template>
  <div class="mt-2">
    <div class="text-editor-content" :id="'editorjs-'+uuid"></div>
  </div>
</template>

<script>
import EditorJS from "@editorjs/editorjs";
import EditorJSHeader from "@editorjs/header";
import EditorJSList from "@editorjs/list";
import EditorJSQuote from "@editorjs/quote";
import EditorJSImage from "@editorjs/image";
import axios from "../../../http";
const uuidv4 = require("uuid/v4");
import debounce from "../../../debounce";

export default {
  props: ["value", "label"],
  data() {
    return {
      input: this.value,
      uuid: uuidv4(),
      editor: null,
      interval: null,
      dirty:false,
      debounced: debounce(() => {
        this.editor.save().then((savedData) => {
          this.$emit("input", savedData);
          this.dirty = false;
        });
      }, 300)
    };
  },
  methods: {
    save() {
      this.dirty = true;
      this.debounced();
    }
  },
  mounted() {
    this.editor = new EditorJS({
      holder: "editorjs-" + this.uuid,
      data: this.input,
      autofocus: true,
      inlineToolbar: true,
      onChange: () => {
        this.save();
      },

      tools: {
        header: {
          class: EditorJSHeader,
          inlineToolbar: true,
          config: {
            placeholder: "Heading",
          },
        },
        quote: {
          class: EditorJSQuote,
          inlineToolbar: true,
          config: {
            quotePlaceholder: "Enter a quote",
            captionPlaceholder: "Quote's author",
          },
        },
        list: {
          class: EditorJSList,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+L",
        },
        image: {
          class: EditorJSImage,
          config: {
            uploader: {
              uploadByFile(file) {
                var formData = new FormData();
                formData.append("asset", file);
                formData.append("type", "image");
                return axios
                  .post("/api/admin/assets", formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then((response) => {
                    return {
                      success: 1,
                      file: {
                        url: response.data.url,
                      },
                    };
                  });
              },
            },
          },
        },
      },
    });
  },
  computed: {
    id() {
      return "text_input_" + this._uid;
    },
  },
  watch: {
    input(input) {
      this.$emit("input", input);
    },
    value(value) {
      this.input = value;
      this.editor.data = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.ce-toolbox {
  pointer-events: none;
}

.ce-toolbox--opened {
  pointer-events: unset;
}

.text-editor-content {
  border: 1px solid rgb(219, 219, 219);
  background: white;
}
</style>