import store from "./store.js";

const heart = {
    beat() {
        store.dispatch("checkUserIsLoggedIn").then(() => {
            store.dispatch("checkLoggedUserIsAdmin").then(() => {
                // All good, user is logged and an admin
            }, () => {
                store.dispatch("logout");
            })
        }, () => {
            store.dispatch("logout");
        })
    }
};

// Periodic check for login status of the current user
setInterval(() => {
    if (store.state.auth.user) {
        heart.beat();
    }
}, 60000);
